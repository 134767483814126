<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-4">Liste des vehicules recherchées</h4>
                    <div class="row justify-content-between">
                        <div class="col-md-5">
                            <div class="form-group mt-3 mb-0">
                                <div class="search-box me-2">
                                    <div class="position-relative">
                                        <input type="text" @input="searchFilter($event)" class="form-control bg-soft bg-info border-0"
                                            placeholder="Rechercher..." />
                                        <i class="bx bx-search-alt search-icon"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row col-md-6">
                            <div class="form-group col-md-4 mt-3 mb-0">
                                <datepicker v-model="picked" :first-day-of-week="1" lang="en" confirm class="form-control"
                                    placeholder="Date de début"></datepicker>
                            </div>

                            <div class="form-group col-md-4 mt-3 mb-0">
                                <datepicker class="form-control" placeholder="Date de fin" v-model="daterange" range
                                    append-to-body lang="en" confirm></datepicker>
                            </div>

                            <div class="mt-3 col-md-4 mb-0">
                                <button class="btn btn-info">
                                    <i class="bx bx-slider align-middle me-1"></i> Lancer le filtre
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="table-responsive mt-3" v-if="!loading">
                        <table class="table table-nowrap table-centered mb-0 align-middle">
                            <thead class="table-light">
                                <tr>
                                    <th>Plaque véhicule</th>
                                    <th>châssis</th>
                                    <th>Marque</th>
                                    <th>Modèle</th>

                                    <!-- <th scope="col">Motif</th> -->
                                    <th scope="col">Type</th>
                                    <th>Conduite à tenir</th>
                                    <!-- <th scope="col">Date</th> -->
                                    <th scope="col">Statut</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="vehicule in vehiculeData" :key=vehicule.id>
                                    <td>{{ vehicule.immatriculation }}</td>
                                    <td>{{ vehicule.numero_chassis }}</td>
                                    <td>{{ vehicule.marque }}</td>
                                    <td>{{ vehicule.modele }}</td>
                                    <!-- <td>{{ vehicule.motif_recherche }}</td> -->
                                    <td>{{ vehicule.type_vehicule }}</td>
                                    <td>{{ vehicule.conduite_a_tenir }}</td>
                                    <!-- <td>{{ vehicule.date }}</td> -->
                                    <td>
                                        <span class="badge font-size-11 m-1 bg-danger" v-if="vehicule.statut == 'rechercher'">Recherché</span>
                                        <span class="badge font-size-11 m-1 bg-success" v-else>Non  Recherché</span>
                                    </td>
                                    <td>
                                        <button class="btn btn-warning btn-sm me-2 w-xs" @click="detailsCar(vehicule)">Details</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                  <div v-else class="loading-container">
                    <div class="text-center">
                      <i class="bx bx-loader bx-spin" style="font-size: 5em;"></i>
                      <h1>Chargement</h1>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <!-- end row -->
    </Layout>
</template>
<style>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh
}
</style>
<script>
import Layout from "../../layouts/main";
import Datepicker from "vue3-datepicker";
import PageHeader from "@/components/page-header";
// import { vehiculeData } from "./vehiculeData";
import { reqMethods } from '../../../state/helpers';


/**
 * Lending component
 */
export default {
    components: {
        Layout, PageHeader,
        Datepicker
    },
    data() {
        return {
            picked: "",
            daterange: "",
          loading: true,
            vehiculeData: {},
            allVehicules: {},
            fetchError: null,
            title: "Liste de vehicule recherchées",
            items: [
                {
                    text: "Dashboard",
                    href: "/"
                },
                {
                    text: "vehicules recherchées",
                    active: true
                }
            ]
        };
    },
    methods: {
        ...reqMethods,
        detailsCar(car){
            localStorage.setItem('car', JSON.stringify(car));
            this.$router.push({ path: `/VehiculeDelail/${car.id}`});
        },
        getAllVehicules() {
            const that = this;
          this.loading = true;
            this.getRequest('/vehicule/vehicule/')
                .then(
                    function (response) {
                        that.vehiculeData = response.data;
                        that.allVehicules = response.data;
                        console.log(that.allVehicules);
                    },
                    function (error) {
                        that.fetchError = error;
                    }
                )  .finally(() => {
              that.loading = false;
            });
        },

        searchFilter(e) {
            const searchStr = e.target.value;
            this.vehiculeData = this.allVehicules.filter((vehicule) => {
                return (
                    vehicule.immatriculation.toLowerCase().includes(searchStr.toLowerCase()) ||
                    vehicule.numero_chassis.toLowerCase().includes(searchStr.toLowerCase()) ||
                    vehicule.marque.toLowerCase().includes(searchStr.toLowerCase()) ||
                    vehicule.motif_recherche.toLowerCase().includes(searchStr.toLowerCase()) ||
                    vehicule.modele.toLowerCase().includes(searchStr.toLowerCase()) 
                );
            });
        },
    },
    mounted() {
        this.getAllVehicules();
    },
};
</script>